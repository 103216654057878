<!-- <a class="btn_caller" (click)="open()"> <img src="assets/img/call_o_o.png" alt="Gatemaan - Call" title="Gatemaan - Call"></a> -->
<!-- <footer class="footer-bottom-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <p>Copyright <i class='bx bx-copyright'></i>2020 <a routerLink="/">Gatemaan</a>. All rights reserved</p>
            </div>

            <div class="col-lg-4 col-md-6">
                <ul>
                    <li><a routerLink="/terms-conditions">Terms & Conditions</a></li>
                    <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                </ul>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="designed">
                    <div class="single-footer-widget" style="margin: 0px;">

                        <ul class="social-icon">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer> -->
<footer class="footer">
    <div class="container">
      <ul class="wow fadeInUp animated animated" style="visibility: visible;">
        <li class="current"><a class="smoothscroll" (click)="closeMenu()" routerLink="/home" title="home">Home</a></li>
        <li><a  class="smoothscroll" routerLink="/about" (click)="closeMenu()" title="about">About Us</a></li>
        <li><a class="smoothscroll"  routerLink="/products" (click)="closeMenu()" title="services">Our Products</a></li>
        <!-- <li><a class="smoothscroll"  href="#works" title="works">Works</a></li> -->
        <li><a  class="smoothscroll" routerLink="/gallery" (click)="closeMenu()"  title="gallery">Gallery</a></li>
        <li><a  class="smoothscroll" routerLink="/privacy-policy" (click)="closeMenu()"  title="gallery">Privacy-Policy</a></li>
        <li><a class="smoothscroll"  routerLink="/contact"  (click)="closeMenu()"title="contact">Contact</a></li>
        <li><a  class="smoothscroll" routerLink="/brouchers"  (click)="closeMenu()"title="Brouchers">Brouchers</a></li>
      </ul>
      <div class="social_icons wow fadeInUp animated animated" style="visibility: visible;">
        <ul>
          <li class="d-inline-block"> <a href="https://www.facebook.com/Gatemaan-A-Vertical-of-NSMT-101129395105393" class="fs24" target="_blank"> <i class="bx bxl-facebook"></i> </a> </li>
          <!-- <li class="d-inline-block"> <a href="https://twitter.com/" class="fs24" target="_blank"><i class="bx bxl-twitter"></i> </a> </li> -->
          <li class="d-inline-block"> <a href="https://www.linkedin.com/company/68846226/admin/" class="fs24" target="_blank"> <i class="bx bxl-linkedin"></i> </a> </li>
            <!-- <li class="d-inline-block"> <a href="https://www.instagram.com/" class="fs24" target="_blank"> <i class="bx bxl-instagram"></i> </a> </li> -->
        </ul>
      </div>
    </div>
  </footer>
  <div class="copyright">
    <div class="container"> © Copyright Gatemaan | 2019 </div>
  </div>
<!-- <div class="go-top">
    <i class='bx bx-chevrons-up bx-fade-up'></i>
    <i class='bx bx-chevrons-up bx-fade-up'></i>
</div> -->
<!-- <a class="btn_caller fade-in one animated zoomIn" [hidden]="active" (click)="open()"> <img src="assets/img/call_o_o.png" alt="Gatemaan - Call" title="Gatemaan - Call"></a>
<div class="modal_caller" [hidden]="!active">
    <div class="head_caller"> <span class="contact_txt_caller">Call Us (Or) Whatsapp Us</span> <a class="close_call_btn_caller" (click)="close()"> <img src="assets/img/call_c_c.png" alt="Gatemaan- Call" title="Gatemaan- Call"></a> </div>
    <div class="content_caller"> <a class="popupNumber" href="tel:+916304982304">+91 6304982304</a></div>
  </div> -->

